<template>
  <div class="edit-participant-block">
    <menu-breadcrumb>
      <el-breadcrumb-item :to="{ name: 'Participant' }"
        >會員管理</el-breadcrumb-item
      >
      <el-breadcrumb-item :to="{ name: 'EditParticipant' }"
        >編輯會員</el-breadcrumb-item
      >
    </menu-breadcrumb>

    <div class="container">
      <el-form
        ref="participantForm"
        :model="participant_form"
        :rules="participant_rules"
        label-position="top"
      >
        <el-form-item label="名稱" prop="name">
          <el-input
            v-model="participant_form.name"
            maxlength="128"
            show-word-limit
          ></el-input>
        </el-form-item>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item prop="username">
              <template #label
                >信箱
                <span class="hint">(不可修改)</span>
              </template>
              <el-input
                v-model="participant_form.username"
                maxlength="256"
                show-word-limit
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="地址" prop="address">
              <el-input
                v-model="participant_form.address"
                maxlength="128"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="職業" prop="job">
              <el-input
                v-model="participant_form.job"
                maxlength="128"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="性別" prop="gender">
              <el-select v-model="participant_form.gender">
                <el-option value="other" label="其他"></el-option>
                <el-option value="male" label="男"></el-option>
                <el-option value="female" label="女"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="年齡" prop="age">
              <el-select v-model="participant_form.age">
                <el-option value="18歲以下" label="18歲以下"></el-option>
                <el-option value="19-24歲" label="19-24歲"></el-option>
                <el-option value="25-34歲" label="25-34歲"></el-option>
                <el-option value="35-44歲" label="35-44歲"></el-option>
                <el-option value="45-54歲" label="45-54歲"></el-option>
                <el-option value="55-64歲" label="55-64歲"></el-option>
                <el-option value="65歲以上" label="65歲以上"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="登入方式" prop="login_type">
              <template #label>
                登入方式
                <span class="hint">(不可修改)</span>
              </template>
              <el-input
                v-model="participant_form.login_type"
                disabled
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="電話" prop="phone">
              <el-input
                v-model="participant_form.phone"
                maxlength="64"
                show-word-limit
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="備註" prop="note">
          <el-input
            type="textarea"
            v-model="participant_form.note"
            maxlength="512"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="flex-end">
            <el-button type="warning" @click="handleRedirect('Participant')"
              >回主頁</el-button
            >
            <el-button type="success" @click="handleSubmit">送出</el-button>
          </div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getParticipant, updateParticipant } from "@/api/participant";

export default {
  name: "EditParticipant",
  data() {
    return {
      participant_form: {
        participant_id: "",
        username: "",
        name: "",
        gender: "",
        job: "",
        phone: "",
        note: "",
        login_type: "",
        address: "",
        age: "",
      },
      participant_rules: {
        name: [
          { required: true, message: "請輸入名稱", trigger: "blur" },
          {
            max: 128,
            message: "長度請勿超過128字",
            trigger: "blur",
          },
        ],
        address: [
          {
            max: 128,
            message: "長度請勿超過128字",
            trigger: "blur",
          },
        ],
        job: [
          {
            max: 128,
            message: "長度請勿超過128字",
            trigger: "blur",
          },
        ],
        gender: [{ required: true, message: "請選擇性別", trigger: "change" }],
        phone: [
          {
            max: 64,
            message: "長度請勿超過64字",
            trigger: "blur",
          },
        ],
        note: [
          {
            max: 512,
            message: "長度請勿超過512字",
            trigger: "blur",
          },
        ],
        age: [
          {
            max: 64,
            message: "長度請勿超過64字",
            trigger: "change",
          },
        ],
      },
    };
  },
  computed: {
    participantId() {
      return +this.$route.params.id;
    },
  },
  created() {
    this.handleGetParticipant();
  },
  methods: {
    async handleGetParticipant() {
      if (isNaN(this.participantId)) {
        this.$message.error("參數錯誤");
        this.handleRedirect("Participant");
        return;
      }

      try {
        const res = await getParticipant(this.participantId);

        Object.keys(this.participant_form).forEach((key) => {
          if (res[key]) {
            this.participant_form[key] = res[key];
          }
        });
      } catch (error) {
        this.handleRedirect("Participant");
      }
    },
    handleSubmit() {
      this.$refs.participantForm.validate(async (valid) => {
        if (valid) {
          try {
            const form = { ...this.participant_form };
            delete form.participant_id;
            console.log(form);
            await updateParticipant(this.participantId, form);
            this.$message.success("修改成功");
            this.handleRedirect("Participant");
          } catch (error) {
            this.$message.error("修改失敗");
          }
        }
      });
    },
    handleRedirect(name) {
      this.$router.push({ name });
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-participant-block {
  .hint {
    color: #f56c6c;
    font-size: 10px;
  }
}
</style>
